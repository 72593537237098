import React, { useState, useRef, useEffect } from 'react';
//import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/bootstrap.min.css';  // Adjust this path as necessary

import FinnishBankUtils from 'finnish-bank-utils'; // Make sure this import is correct
import Barcode from 'react-barcode';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import './App.css';


function IbanForm() {
  // Initialize states with empty values for user input
  const [iban, setIban] = useState(localStorage.getItem('iban') || '');
  const [sum, setSum] = useState(localStorage.getItem('sum') || '');
  const [dueDate, setDueDate] = useState(
    localStorage.getItem('dueDate')
      ? new Date(localStorage.getItem('dueDate'))
      : new Date()
  );  const [validationState, setValidationState] = useState('');
  const [barcode, setBarcode] = useState('');
  const [error, setError] = useState('');
  const barcodeRef = useRef(null);
  const referenceNumber = '55958 22432 94671'; // Example of a hard-coded valid reference number
  const [isCopied, setIsCopied] = useState(false);
  const [rangeValue, setRangeValue] = useState(30); // or whatever default value you want



  useEffect(() => {
    // Save input field values to localStorage whenever they change
    localStorage.setItem('iban', iban);
    localStorage.setItem('sum', sum);
    localStorage.setItem('dueDate', dueDate.toISOString());
  }, [iban, sum, dueDate]);


  useEffect(() => {
    // Attempt to retrieve and parse the sum from localStorage
    const storedSum = localStorage.getItem('sum');
    const parsedSum = storedSum !== null ? parseFloat(storedSum) : null;
  
    // If there's a valid number, set it within the allowed range and update rangeValue
    if (parsedSum && !isNaN(parsedSum)) {
      const initialRangeValue = Math.min(Math.max(parsedSum, 30), 300); // Adjust these bounds as necessary
      setRangeValue(initialRangeValue);
    }
  }, []); // Empty dependency array to run only on mount


  const handleDateChange = (date) => {
    setDueDate(date);
  };


const copyToClipboard = (e) => {
  barcodeRef.current.select();
  document.execCommand('copy');
  e.target.focus();
  setIsCopied(true);  // Set the state to true when copied

  // Hide the popover after 2 seconds
  setTimeout(() => {
    setIsCopied(false);
  }, 1000);
};


  const handleIbanChange = (e) => {
    const input = e.target.value;
    // Only keep alphanumeric characters and spaces (for better user experience)
    let value = input.replace(/[^A-Za-z0-9 ]/g, '');
  
    // Split the value into groups of 4 characters separated by spaces
    const match = value.split(' ').join('').match(/.{1,4}/g);
    
    let formattedValue = match ? match.join(' ') : '';
  
    // Check if we should trigger validation. We do this by counting the actual characters entered,
    // ignoring spaces, to know when the user has entered a complete IBAN.
    const actualLength = formattedValue.replace(/ /g, '').length;
  
    setIban(formattedValue);
    setError(''); // Clear any previous errors
  
    if (actualLength === 18) { // Checking the length of the IBAN without spaces
      validateIban(formattedValue); // You could validate the IBAN here
    } else {
      setValidationState(''); // Reset validation state if the length requirement is not met
    }
  };
  
  
  
  const validateIban = (iban) => {
    // Implement your validation logic here
    const isValidIban = FinnishBankUtils.isValidFinnishIBAN(iban.replace(/\s/g, '')); // validation without spaces
    setValidationState(isValidIban ? 'is-valid' : 'is-invalid');
  
   
  };
  

  const validateAndGenerateBarcode = () => {
  
    const parsedSum = parseFloat(sum);
    if (isNaN(parsedSum)) {
      setError('Invalid sum. Please provide a numeric value.');
      return;
    }
  
    // Format the date in the format "dd.MM.yyyy"
    const formattedDate = format(dueDate, 'dd.MM.yyyy');
  
    // Generate barcode
    try {
      const generatedBarcode = FinnishBankUtils.formatFinnishVirtualBarCode({
        iban: iban,
        sum: parsedSum,
        reference: referenceNumber,
        date: formattedDate, // pass the formatted date string here instead of the Date object
      });
      setBarcode(generatedBarcode);
    } catch (error) {
      console.error("Error generating barcode:", error);
      setError('There was an error generating the barcode. Please check your inputs and try again.');
    }
  };

// This can be placed inside your IbanForm component
const CopiedPopover = () => {
  if (!isCopied) return null; // If isCopied is false, don't render anything

  return (
    <div style={{
      position: 'absolute',
      top: '10%', // Adjust thes values as needed
      left: '90%',
      transform: 'translate(-50%, -50%)',
      padding: '10px',
      backgroundColor: '#333',
      color: '#fff',
      textAlign: 'center',
      borderRadius: '6px',
      zIndex: 1000, // Make sure it's on top of other items
    }}>
      Copied
    </div>
  );
};



  return (
    
    <div className="container mt-5">
      
<div className="mb-3">
  <label htmlFor="ibanField" className="form-label">Tilinumero</label>
  <input 
    type="text"
    className={`form-control ${validationState}`}
    id="ibanField"
    value={iban}
    onChange={handleIbanChange}
    placeholder="FI29 1220 3500 6278 75"
    maxLength="22" 
  />
</div>
      <div className="mb-3">
  <label htmlFor="sumField" className="form-label">Summa</label>
  <input 
    type="number" 
    className="form-control"
    id="sumField"
    value={sum}
    onChange={(e) => setSum(e.target.valueAsNumber || '')} // Ensuring we're working with a number, and prevent null on deletion
    placeholder="97.20"
    min="0" // Prevents negative amounts
    step="0.01" // Allows cents to be entered
    pattern="\d+(\.\d{2})?" // This pattern demands at least the cents part to be entered, you can remove it if you want more flexibility
    inputMode="decimal" // Brings up the numeric keypad on supported devices, useful for monetary values
  />
</div>
<div className="mb-3">
<input 
  type="range" 
  className="form-range" 
  min="30" 
  max="300" 
  step="0.1" 
  value={rangeValue} // link the rangeValue state to value
  onChange={(e) => {
    const newValue = parseFloat(e.target.value);
    setRangeValue(newValue); // update rangeValue state
    setSum(newValue.toFixed(2)); // update sum state, and keep 2 decimals for cents
  }} 
  id="customRange3"
/>
</div>
<div className="mb-3">
  <label htmlFor="dueDateField" className="form-label">Eräpäivä </label>
  <br />
  <DatePicker
    selected={dueDate}
    onChange={handleDateChange}
    dateFormat="dd.MM.yyyy"
    className="form-control"
    id="dueDateField"
  />
</div>
      <div className="mb-3">
        <button 
          className="btn btn-primary" 
          onClick={validateAndGenerateBarcode}
        >
          Luo Viivakoodi
        </button>
      </div>
  
      {error && (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      )}
  
  {barcode && (
    
        <div className="input-group mb-3">
          <input 
            type="text"
            ref={barcodeRef}
            value={barcode}
            className="form-control"
            readOnly
          />
  <div className="input-group-append">
  <CopiedPopover /> {/* Here's the popover */}

  <button
  className="btn btn-outline-secondary"
  type="button"
  onClick={copyToClipboard}
  data-toggle="tooltip"
  data-placement="top"
  title="Copy to Clipboard"
>
  Copy
</button>
  </div>
          <div className="mb-3">
          <div className="barcode-container">
          <Barcode value={barcode} format="CODE128" />
          </div>

        </div>
        </div>
        
      )}
    </div>
  );
  
}

export default IbanForm;
